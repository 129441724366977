import React from "react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import Chip from "../components/Chip";
import { collectionIcon, deliveryIcon } from "../../../../../blocks/dashboard/src/assets";

export type Order = {
    id: string;
    type: string;
    attributes: {
        id: number;
        order_number: string;
        placed_at: string;
        order_type: string;
        status: string;
        payment_source: string;
        items_count: number;
        restaurant: string;
        total: string;
        delivery_addresses: {
            id: number;
            account_id: number;
            address: string;
            name: string;
            flat_no: null | string;
            zip_code: string;
            phone_number: string;
            deleted_at: null | string;
            latitude: null | string;
            longitude: null | string;
            residential: boolean;
            city: string;
            state_code: null | string;
            country_code: null | string;
            state: null | string;
            country: string;
            address_line_2: null | string;
            address_type: string;
            address_for: string;
            is_default: boolean;
            landmark: null | string;
            created_at: string;
            updated_at: string;
        }[];
        reward_points: null | number;
        estimated_time: string;
        restaurant_detail: {
            id: number;
            title: string;
            location: string;
            contact: string;
            open_time: string;
            close_time: string;
            postcode: string;
            created_at: string;
            updated_at: string;
            order_type: string[];
            tax_reg_no: string;
            estimated_delivery_time: number;
            estimated_collection_time: number;
            latitude: string;
            longitude: string;
        };
        otp: number;
    };
};

interface Props {
    actionCell: (info: CellContext<Order, any>) => JSX.Element;
}

export const storeAdminColumns = ({actionCell}: Props) => {
    const columns: ColumnDef<Order, any>[] = [
        {
            header: "Order Type",
            accessorKey: "order_type",
            cell: info => {
                switch(info.getValue()) {
                    case "delivery":
                        return <Chip
                            style={{ backgroundColor: "#D1FAE5" }}
                            icon={<img src={deliveryIcon} alt="delivery" />}
                            label={info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1)}
                        />;
                    case "collection":
                        return <Chip
                            style={{ backgroundColor: "#FEF3C7" }}
                            icon={<img src={collectionIcon} alt="collection" />}
                            label={info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1)}
                        />;
                    default:
                        return <Chip
                            label={info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1)}
                        />;
                }
            },
        },
        {
            header: "Order Status",
            accessorKey: "status",
            cell: info => {
                const status = info.getValue().charAt(0).toUpperCase() + info.getValue().slice(1);
                return status.replace(/_/g, " ");
            },
        },
        {
            header: "Order Number",
            accessorKey: "order_number",
            cell: info => "#" + info.getValue(),
        },
        {
            header: "Items",
            accessorKey: "items_count",
            cell: info => info.getValue() + "x",
        },
        {
            header: "Amount",
            accessorKey: "total",
            cell: info => `£${info.getValue()}`,
        },
        {
            header: "Action",
            accessorKey: "action",
            cell: info => actionCell(info),
        },
    ];

    return columns;
}