import React from "react";
import { useReactTable, ColumnDef, getCoreRowModel, flexRender, TableOptions } from "@tanstack/react-table";
import "./DataGrid.css";

interface Props<TData, TValue> {
    data: any[];
    columns: ColumnDef<TData, TValue>[];
    tableOptions?: Partial<TableOptions<TData>>;
}

function DataGrid<TData, TValue> ({ data, columns, tableOptions }: Props<TData, TValue>) {
    const rows = React.useMemo(() => data, [data]);
    const columnsMemo = React.useMemo(() => columns, []);
    
    const table = useReactTable<TData>({
        ...tableOptions,
        data: rows,
        columns: columnsMemo,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        getCoreRowModel: getCoreRowModel(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
    });
    
    if (table.getRowModel().rows.length === 0) {
        return (
            <div className="datagrid__empty_rows__wrapper">
                <p className="datagrid__empty_rows__text">Your Order list is empty!</p>
            </div>
        )
    }

    return (
        <table data-testid='datagrid' className="datagrid__container">
            <thead className="datagrid__header">
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id} className="datagrid__header__row">
                        {headerGroup.headers.map(header => {
                            return (
                                <th
                                    key={header.id}
                                    style={{ position: 'relative', width: header.getSize() }}
                                    className="datagrid__header__cell"
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                    {header.column.getCanResize() && (
                                        <div
                                            onMouseDown={header.getResizeHandler()}
                                            onTouchStart={header.getResizeHandler()}
                                            className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''
                                                }`}
                                        ></div>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                ))}
            </thead>
            <tbody className="datagrid__body">
                {table.getRowModel().rows.map(row => {
                    return (
                        <tr key={row.id} className="datagrid__body__row">
                            {row.getVisibleCells().map(cell => {
                                return (
                                    <td key={cell.id} style={{ width: cell.column.getSize() }} className="datagrid__body__cell">
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    );
};

export default DataGrid;