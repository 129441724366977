import React from "react";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Input, MenuItem, Select, SelectChangeEvent, SvgIcon } from "@mui/material";
import { Area, AreaChart, BarChart, Bar, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CellContext } from "@tanstack/react-table";
import StoreAdminController, { Props } from "./StoreAdminController";
import Menu from "../../../../../components/src/Menu";
import DataGrid from "../../../../../components/src/Dashboard/DataGrid";
import { storeAdminColumns, Order } from "../../../../../components/src/Dashboard/DataGrid/columns/StoreAdminColumns";
import "./StoreAdminDashboard.css";

export default class StoreAdminDashboard extends StoreAdminController {
    constructor(props: Props) {
        super(props);
    }

    checkPercentageStatus = (percentage: number) => {
        const percentageString = percentage.toString();

        if (percentageString.includes("-")) {
            return "negative";
        } else if (percentageString === "0") {
            return "neutral";
        } else {
            return "positive";
        }
    }

    renderArrowSign = (percentage: number) => {
        const status = this.checkPercentageStatus(percentage);

        switch (status) {
            case "positive":
                return (
                    <SvgIcon className="card_footer__icon" style={{ color: "#2C6F37" }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg"><path d="M21.87 19.49A1 1 0 0 1 21 20a1 1 0 0 1-.49-.13L12 15.14l-8.51 4.73a1 1 0 1 1-1-1.74l9-5a1 1 0 0 1 1 0l9 5a1 1 0 0 1 .38 1.36M3.49 10.87 12 6.14l8.51 4.73A1 1 0 0 0 21 11a1 1 0 0 0 .49-1.87l-9-5a1 1 0 0 0-1 0l-9 5a1 1 0 1 0 1 1.74" fill="inherit"/></svg>
                    </SvgIcon>
                );
            case "negative":
                return (
                    <SvgIcon className="card_footer__icon" style={{ color: "#E11B22" }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" data-name="Flat Color" xmlns="http://www.w3.org/2000/svg"><path d="M2.13 4.51A1 1 0 0 1 3 4a1 1 0 0 1 .49.13L12 8.86l8.51-4.73a1 1 0 0 1 1 1.74l-9 5a1 1 0 0 1-1 0l-9-5a1 1 0 0 1-.38-1.36m18.38 8.62L12 17.86l-8.51-4.73A1 1 0 0 0 3 13a1 1 0 0 0-.49 1.87l9 5a1 1 0 0 0 1 0l9-5a1 1 0 1 0-1-1.74" fill="inherit" /></svg>
                    </SvgIcon>
                );
            case "neutral":
                return (
                    <SvgIcon className="card_footer__icon" style={{ color: "#7d7d7d" }}>
                        <svg width="24" height="24" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg"><path d="M13.797 22.691h28.43c1.125 0 2.086-.96 2.086-2.109 0-1.148-.961-2.086-2.086-2.086h-28.43c-1.125 0-2.11.938-2.11 2.086s.985 2.11 2.11 2.11m0 14.813h28.43c1.125 0 2.086-.938 2.086-2.086s-.961-2.11-2.086-2.11h-28.43c-1.125 0-2.11.962-2.11 2.11s.985 2.086 2.11 2.086" /></svg>
                    </SvgIcon>
                );
        }
    }

    openActionMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ actionMenuAnchorEl: event.currentTarget });
    };

    closeActionMenu = () => {
        this.setState({ actionMenuAnchorEl: null });
    }

    openPrintDialog = () => {
        this.setState({ printDialog: true });
    }

    closePrintDialog = () => {
        this.setState({ printDialog: false });
    }

    actionCell = (info: CellContext<Order, any>) => {
        const open = Boolean(this.state.actionMenuAnchorEl);

        return (
            <div>
                <IconButton onClick={this.openActionMenu}>
                    <SvgIcon>
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 7.5a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5m0 9a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5m0-4.5a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5" fill="inherit" /></svg>
                    </SvgIcon>
                </IconButton>
                <Menu
                    anchorEl={this.state.actionMenuAnchorEl}
                    open={open}
                    onClose={this.closeActionMenu}
                    options={[
                        { label: "Print Receipt", onClick: this.openPrintDialog, icon: <SvgIcon><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M18 17H6v-2h12zm0-4H6v-2h12zm0-4H6V7h12zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2z"/></svg></SvgIcon> },
                        { label: "View Details", onClick: () => {}, icon: <SvgIcon><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 12q1.406 0 2.39-.984t.985-2.391q0-1.405-.984-2.391Q10.406 5.25 9 5.25t-2.391.984q-.984.986-.984 2.391 0 1.407.984 2.391t2.39.984m0-1.35q-.844 0-1.434-.591a1.95 1.95 0 0 1-.591-1.434q0-.843.59-1.435Q8.156 6.6 9 6.6q.843 0 1.434.59.59.59.59 1.435 0 .844-.59 1.434A1.95 1.95 0 0 1 9 10.65m0 3.6q-2.607 0-4.763-1.378A8.77 8.77 0 0 1 .975 9.15.6.6 0 0 1 .9 8.915a2.2 2.2 0 0 1 0-.581.6.6 0 0 1 .075-.234 8.76 8.76 0 0 1 3.262-3.721Q6.393 2.999 9 3t4.762 1.378A8.76 8.76 0 0 1 17.025 8.1q.056.094.075.234a2.2 2.2 0 0 1 0 .581.6.6 0 0 1-.075.235 8.77 8.77 0 0 1-3.263 3.722Q11.606 14.25 9 14.25m0-1.5q2.119 0 3.89-1.116a7.33 7.33 0 0 0 2.71-3.009 7.33 7.33 0 0 0-2.71-3.01A7.16 7.16 0 0 0 9 4.5q-2.12 0-3.891 1.115a7.33 7.33 0 0 0-2.71 3.01 7.33 7.33 0 0 0 2.71 3.009 7.16 7.16 0 0 0 3.89 1.116" fill="#1D1D1D"/></svg></SvgIcon> },
                        { label: "Accept Order", onClick: () => this.orderAction(info.row.id, "accept"), icon: <SvgIcon><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.596 11.903 3.994 9.301a.747.747 0 1 0-1.058 1.057l3.135 3.135a.747.747 0 0 0 1.058 0l7.935-7.935a.747.747 0 1 0-1.058-1.057z" fill="#1D1D1D"/></svg></SvgIcon> },
                        { label: "Cancel Order", onClick: () => this.orderAction(info.row.id, "cancel"), icon: <SvgIcon><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m11.093 2.468.532.532H13.5c.412 0 .75.337.75.75 0 .412-.338.75-.75.75h-9a.75.75 0 0 1-.75-.75c0-.413.338-.75.75-.75h1.875l.532-.533a.76.76 0 0 1 .526-.217h3.135c.194 0 .39.083.524.217M4.5 14.25c0 .825.675 1.5 1.5 1.5h6c.825 0 1.5-.675 1.5-1.5v-7.5c0-.825-.675-1.5-1.5-1.5H6c-.825 0-1.5.675-1.5 1.5zm2.25-7.5h4.5c.412 0 .75.338.75.75v6c0 .412-.338.75-.75.75h-4.5A.75.75 0 0 1 6 13.5v-6c0-.412.338-.75.75-.75" fill="#1D1D1D"/></svg></SvgIcon> },
                        { label: "Skip Status", onClick: () => this.orderAction(info.row.id, "skip"), icon: <SvgIcon><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 12.75v-7.5c0-.412.338-.75.75-.75s.75.338.75.75v7.5c0 .412-.338.75-.75.75a.75.75 0 0 1-.75-.75m-1.988-3.135-4.327 3.053a.754.754 0 0 1-1.185-.615V5.947c0-.607.683-.96 1.185-.615l4.328 3.06a.75.75 0 0 1 0 1.223" fill="#1D1D1D"/></svg></SvgIcon> },
                    ]}
                >
                </Menu>
            </div>
        )
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
          [e.target.name]: e.target.value,
        } as unknown as Pick<this['state'], keyof this['state']>);
    }

    handleCardFooter = (percentage: number) => {
        const status = this.checkPercentageStatus(percentage);

        switch (status) {
            case "positive":
                return "+" + percentage + "%" + " than usual"
            default:
                return percentage + "%" + " than usual"
        }
    };

    render() {
        const { orderStatistics, data } = this.state;
        const columns = storeAdminColumns({ actionCell: this.actionCell });
        const cards = [
            {
                title: "New Orders",
                icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" fill="#1D1D1D"><path d="M29.76 20.325a2.2 2.2 0 0 0-1.958-3.2H3.253a2.2 2.2 0 0 0-2.2 2.199c0 1.11.828 2.03 1.898 2.177l.582 1.978a2.56 2.56 0 0 0 2.44 1.826h11.933a.335.335 0 0 0 0-.67H5.974a1.885 1.885 0 0 1-1.798-1.345l-.52-1.767H22.22a5.38 5.38 0 0 0-1.039 3.182 5.416 5.416 0 0 0 5.41 5.41 5.416 5.416 0 0 0 5.41-5.41c0-1.8-.884-3.396-2.24-4.38m-3.17 9.12a4.746 4.746 0 0 1-4.74-4.74 4.75 4.75 0 0 1 4.74-4.74c2.545-.021 4.758 2.112 4.74 4.74a4.746 4.746 0 0 1-4.74 4.74M4.522 16.237l.032.001c.17 0 .316-.13.333-.303a10.63 10.63 0 0 1 7.912-9.345 10.9 10.9 0 0 1 5.447 0 10.65 10.65 0 0 1 7.913 9.265.335.335 0 1 0 .666-.067 11.35 11.35 0 0 0-3.66-7.259 11.3 11.3 0 0 0-4.542-2.526V4.97a3.1 3.1 0 0 0-3.096-3.096 3.1 3.1 0 0 0-3.096 3.096v1.031a11.3 11.3 0 0 0-4.572 2.554 11.35 11.35 0 0 0-3.639 7.316.335.335 0 0 0 .302.365"/><path d="M19.72 25.445a.469.469 0 1 0 0-.937.469.469 0 0 0 0 .937m9.77-1.068h-2.565V21.81a.335.335 0 1 0-.67 0v2.566H23.69a.335.335 0 1 0 0 .67h2.566v2.564a.335.335 0 0 0 .669 0v-2.565h2.565a.335.335 0 0 0 0-.67M7.277 5.445a.335.335 0 0 0 .58-.334L6.74 3.175a.335.335 0 0 0-.58.335zM2.27 10.912c.329.012.464-.467.167-.625L.502 9.17a.335.335 0 0 0-.335.58l1.936 1.117q.08.045.167.045m26.515 0a.33.33 0 0 0 .167-.045l1.935-1.117a.335.335 0 1 0-.335-.58l-1.935 1.117c-.297.158-.16.637.168.625M23.32 5.568a.335.335 0 0 0 .457-.123l1.117-1.935a.335.335 0 1 0-.58-.335l-1.117 1.936a.335.335 0 0 0 .123.457"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h32v32H0z"/></clipPath></defs></svg>,
                label: data.new_orders_count,
                footer: "Updated every new order",
            },
            {
                title: "Total Orders",
                icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 5.33h1.333C26.8 5.33 28 6.53 28 7.998v18.667c0 1.467-1.2 2.667-2.667 2.667H6.667A2.67 2.67 0 0 1 4 26.664V7.997a2.666 2.666 0 0 1 2.667-2.666H8V3.997c0-.733.6-1.333 1.333-1.333.734 0 1.334.6 1.334 1.333v1.334h10.666V3.997c0-.733.6-1.333 1.334-1.333.733 0 1.333.6 1.333 1.333zm-13.333 9.334h10.666c.734 0 1.334.6 1.334 1.333 0 .734-.6 1.334-1.334 1.334H10.667c-.734 0-1.334-.6-1.334-1.334 0-.733.6-1.333 1.334-1.333m13.333 12H8c-.733 0-1.333-.6-1.333-1.333V11.997h18.666v13.334c0 .733-.6 1.333-1.333 1.333m-13.333-6.667h6.666c.734 0 1.334.6 1.334 1.334 0 .733-.6 1.333-1.334 1.333h-6.666c-.734 0-1.334-.6-1.334-1.333 0-.734.6-1.334 1.334-1.334" fill="#1D1D1D"/></svg>,
                label: data.total_orders_count,
                footer: this.handleCardFooter(data.percentage_change_in_total_orders)
            },
            {
                title: "Completed",
                icon: <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.605 6.938h13.333c.734 0 1.334.6 1.334 1.333s-.6 1.333-1.334 1.333H3.605c-.734 0-1.334-.6-1.334-1.333 0-.734.6-1.334 1.334-1.334m0 5.333h13.333c.734 0 1.334.6 1.334 1.333 0 .734-.6 1.334-1.334 1.334H3.605c-.734 0-1.334-.6-1.334-1.334 0-.733.6-1.333 1.334-1.333m0 8h8c.733 0 1.333-.6 1.333-1.334 0-.733-.6-1.333-1.333-1.333h-8c-.734 0-1.334.6-1.334 1.334 0 .733.6 1.333 1.334 1.333m25.733-4.933-.12-.12a1.316 1.316 0 0 0-1.88.013l-6.373 6.387-3.067-3.067c-.52-.52-1.36-.52-1.88 0l-.12.12a1.33 1.33 0 0 0 0 1.88l4.12 4.12c.52.52 1.36.52 1.88 0l7.44-7.453c.52-.52.52-1.36 0-1.88" fill="#1D1D1D"/></svg>,
                label: data.completed_orders_count,
                footer: this.handleCardFooter(data.percentage_change_in_completed_orders)
            }
        ];

        return (
            <div className="dashboard__grid dashboard__scroll">
                <div className="Toastify" />

                <div className="dashboard_grid__item content">
                    <section className="dashboard_item__orders">
                        <h2 className="dashboard_item__orders__title">
                            Orders
                        </h2>

                        <div className="dashboard_item__cards">
                            {cards.map((card, index) => (
                                <div key={index} className="dashboard_item__card">
                                    <div className="card__header">
                                        <h3 className="card__title">{card.title}</h3>

                                        <IconButton classes={{ root: "card__icon_button" }}>
                                            <SvgIcon>
                                                {card.icon}
                                            </SvgIcon>
                                        </IconButton>
                                    </div>
                                    <span className="card__label">{card.label}</span>
                                    <span className="card__footer">{card.footer}</span>
                                </div>
                            ))}
                        </div>
                    </section>

                    <section className="dashboard__datagrid">
                        <DataGrid
                            data={data.active_orders.map((order: Order) => order.attributes)}
                            columns={columns}
                            tableOptions={{
                                getRowId: (row: Order) => row.id
                            }}
                        />

                        <Dialog open={this.state.printDialog} onClose={this.closePrintDialog}>
                            <DialogTitle>Print Receipt</DialogTitle>
                            <DialogContent>
                                <div className="receipt__print">
                                    <Input name="receiptText" type="text" placeholder="Receipt Text" value={this.state.receiptText} onChange={this.handleInputChange} className="receipt__print__input" />
                                    <Input name="printerName" type="text" placeholder="Enter your printer name" value={this.state.printerName} onChange={this.handleInputChange} className="receipt__print__input" />
                                    <Input name="cupsServerIP" type="text" placeholder="Enter your CUPs server IP" value={this.state.cupsServerIP} onChange={this.handleInputChange} className="receipt__print__input" />
                                    <Button data-testid="print-button" variant="contained" className="receipt__print__button" onClick={this.handlePrint}>
                                        Print
                                    </Button>
                                    {this.state.printReceiptResponse && <span className="receipt__print__response">{this.state.printReceiptResponse}</span>}
                                </div>
                            </DialogContent>
                        </Dialog>
                    </section>
                </div>

                <div className="dashboard_grid__item analytics">
                    <section className="analytics__card">
                        <h2 className="analytics__card__title">Order Statistics</h2>

                        <div style={{ width: '100%', height: 300 }}>
                            <ResponsiveContainer>
                                <AreaChart
                                    data={orderStatistics.daily_completed_orders}
                                    margin={{
                                        top: 0,
                                        right: 0,
                                        left: -24,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="day" />
                                    <YAxis />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="order_count" strokeWidth={1.5} stroke="#2C6F37" fill="#8884d8" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>

                        <div className="analytics__card__footer">
                            <div className="analytics__footer__items">
                                {this.renderArrowSign(orderStatistics.percentage_change)}

                                <span className="footer__label">{orderStatistics.total_change}</span>
                                <span className="footer__subtext">{orderStatistics.percentage_change}%</span>
                            </div>

                            <span className="footer__caption">
                                Compared from Last Week
                            </span>
                        </div>
                    </section>

                    <section className="analytics__card">
                        <h2 className="analytics__card__title">Revenue Report</h2>

                        <div className="analytics__header">
                            <div className="analytics_header__total_revenue">
                                <span className="header__label">Total Revenue:</span>
                                <span className="header__value">&pound;{this.state.orderRevenue.total_last_7_days_revenue}</span>
                            </div>

                            <Select
                                datat-testid="order-type"
                                labelId="order-type"
                                id="order-type"
                                value={this.state.orderType}
                                MenuProps={{
                                    classes: { paper: "analytics_header__select__menu" }
                                }}
                                onChange={(event: SelectChangeEvent) => 
                                    this.setState({ orderType: event.target.value as "collection" | "delivery" })}
                                classes={{
                                    root: "analytics_header__select",
                                    select: "analytics_header__select__input",
                                }}
                            >
                                <MenuItem classes={{ root: "analytics_header__select__item", selected: "analytics_header__select__item selected" }} value="delivery">Delivery</MenuItem>
                                <MenuItem classes={{ root: "analytics_header__select__item", selected: "analytics_header__select__item selected" }} value="collection">Collection</MenuItem>
                            </Select>
                        </div>

                        <div style={{ width: '100%', height: 300 }}>
                            <ResponsiveContainer>
                                <BarChart
                                    data={this.state.orderRevenue.last_7_days_revenue}
                                    margin={{
                                        top: 0,
                                        right: 0,
                                        left: -24,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="type" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="success_amount" fill="#34d399" />
                                    <Bar dataKey="cancel_amount" fill="#e7494e" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        <div className="analytics__card__footer">
                            <div className="analytics__footer__items">
                                {this.renderArrowSign(this.state.orderRevenue.percentage_change)}

                                <span className="footer__label">&pound;{this.state.orderRevenue.total_last_7_days_revenue}</span>
                                <span className="footer__subtext">{this.state.orderRevenue.percentage_change}%</span>
                            </div>

                            <span className="footer__caption">
                                Compared from Last Week
                            </span>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}